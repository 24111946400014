import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import ContactBox from '@components/molecules/ContactBox';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ListCards from '@components/organisms/ListCards';
import ItemBullet from '@components/molecules/items/ItemBullet';
// import ListRelatedProjects from '@components/organisms/ListRelatedProjects';

// assets
import imgStrategy from '@assets/images/ico-strategy.svg';
import imgAudit from '@assets/images/ico-strategy-audit.svg';
import imgBusinessPositioning from '@assets/images/ico-strategy-business-positioning.svg';
import imgIdeation from '@assets/images/ico-strategy-ideation.svg';
import imgResearch from '@assets/images/ico-strategy-research.svg';
import imgStrategicForesight from '@assets/images/ico-strategy-strategic-foresight.svg';
import imgIcoStrategy from '@assets/images/ico-strategy-strategy.svg';
import iconBullet1 from '@assets/images/bullet_1.svg';
import iconBullet2 from '@assets/images/bullet_2.svg';
import iconBullet3 from '@assets/images/bullet_3.svg';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-strategy';

const footerRelatedLinks = [
	{ title: 'Branding', url: '/en/swiss-branding-agency/' },
	{ title: 'Web solutions', url: '/en/swiss-web-agency/' },
];

const stepsList = [
	{
		image: iconBullet1,
		title: 'Share your challenge with us',
		text: [
			"Every great adventure begins with a single step. We'll get together to understand your strategic challenges or objective before proposing a tailor-made process.",
			<>
				<b>Tell us about your needs:</b>
				<br />
				Positioning
				<br />
				Increased impact
				<br />
				Communication strategy
			</>,
		],
	},
	{
		image: iconBullet2,
		title: 'We create a bespoke strategy',
		text: [
			'We pinpoint opportunities and possibilities to build a clear strategic plan, which we follow through on to ensure its success.',
			<>
				Consulting & Analysis
				<br />
				Strategic workshops
				<br />A step-by-step plan
			</>,
		],
	},
	{
		image: iconBullet3,
		title: 'Reach your goal',
		text: [
			'We implement the strategy by enlisting the help of our internal talent or proposing external partners.',
			"We analyze the impact of the strategy's performance.",
		],
	},
];

const cardsList = [
	{
		image: imgBusinessPositioning,
		title: 'Business positioning',
		alt: 'Company positioning strategy',
		text:
			'Strategically position your business to amplify growth and opportunities.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgIcoStrategy,
		title: 'Strategy',
		alt: 'Swiss Digital agency - Lausanne',
		text:
			'Develop a winning strategy that positively impacts your business while making it more sustainable in the long run.',
	},
	{
		image: imgResearch,
		title: 'Research',
		alt: 'User research & interview',
		text:
			'Dig into the value proposition you offer to come up with the ideal solution for both your users/customers and your business.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgIdeation,
		title: 'Ideation',
		alt: 'Strategic ideation',
		text:
			'Conceptualize new solutions and ideas to future-proof your business.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgStrategicForesight,
		title: 'Strategic foresight',
		alt: 'Strategic Foresight',
		text:
			'Facilitate change and adopt new solutions that fall in step with your market’s trends.',
	},
	{
		image: imgAudit,
		title: 'Audit',
		alt: 'Audit and strategic analysis',
		text: `Analyze your brand, products and services to pinpoint where there's room for improvement.`,
	},
];

// --------------------------------
// #endregion
// --------------------------------

const StrategyPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				projectImages: allFile(
					filter: {
						relativePath: { in: ["projects/MJF-Swipe-SQUARE.jpg"] }
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 620) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey]['edges'].find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	// const caseStudiesList = [
	// 	{
	// 		image: {
	// 			...data.getImageAttributes(
	// 				'projectImages',
	// 				'projects/MJF-Swipe-SQUARE.jpg'
	// 			),
	// 			alt: '',
	// 		},
	// 		uptitle: 'Montreux Jazz Festival',
	// 		question:
	// 			'Simplifying the understanding and accessibility of the Montreux Jazz Festival lineup',
	// 		title: 'MJF Swipe',
	// 		url: '/todo',
	// 	},
	// ];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[
				{ uri: '/fr/agence-strategie-digitale/', lang: 'fr' },
			]}
			seo={{
				description: `Digital communication and web solutions agency focused on the impact of brands. Advice, strategies, websites, e-commerce or application and brand creation.`,
			}}
			title="Swiss digital strategy agency - Lausanne, Geneva"
		>
			<HeroPage
				image={imgStrategy}
				title={'Strategy'}
				subtitle={'Swiss digital strategy agency - Lausanne, Geneva'}
				alt={'Swiss digital strategy agency - Lausanne, Geneva'}
			/>

			<section
				className="grid text-content text-center"
				aria-label="Introduction"
				data-animation-page
			>
				<h2 className="col-big">
					Increase your impact <br /> Turbo boost your development
				</h2>
				<p className="p-highlight col-big">
					Business innovation and Strategy
				</p>
			</section>

			<section className="grid grid-content" aria-label="Description">
				<div className="box text-content" data-animation-page>
					<h2>Spark changes that will transform your business</h2>
					<h3>Would you like to:</h3>
					<ul className="list-two-columns list-default list-brand">
						<li>
							<p>
								Find concrete innovative solutions to your
								challenges?
							</p>
						</li>
						<li>
							<p>
								Stop the guesswork so you can make decisions
								based on real data?
							</p>
						</li>
						<li>
							<p>
								See the general overview or get a more detailed
								perspective?
							</p>
						</li>
						<li>
							<p>
								Come up with a winning strategy to future-proof
								your business?
							</p>
						</li>
					</ul>
					<ButtonPrimary
						className="element-left"
						href="#message"
						text="Tell us about your challenge"
					/>
				</div>
			</section>

			{/* <section
				className="grid grid-content text-content text-center"
				aria-label="Projects"
				data-animation-page
			>
				<p className="uptitle">Case studies</p>
				<h2>A real-life example</h2>
				<ListRelatedProjects
					className={['col-big']}
					type="casestudy"
					modifiers="casestudy"
					projectsList={caseStudiesList}
				/>
			</section> */}

			<section aria-label="Process">
				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">
							The three steps to a successful strategy
						</p>
						<h2>What to do&nbsp;?</h2>
						<div className="list-three-columns">
							{stepsList.map((step, index) => (
								<ItemBullet
									key={`step-${index}`}
									image={step.image}
									title={step.title}
									text={step.text}
								/>
							))}
						</div>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Contact">
				<ContactBox
					title={
						<>
							Tell us about
							<br />
							your idea
						</>
					}
					link={{ title: 'Email us', url: '#message' }}
					text={
						<>
							Or call:{' '}
							<Link href="tel:+41216521818">
								+41 21 652 18 18
							</Link>
						</>
					}
				/>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Services"
				data-animation-page
			>
				<p className="uptitle">Areas of expertise</p>
				<h2>Business strategy services</h2>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section>

			<section className="grid" aria-label="Send Message" id="message">
				<FormMessage
					title="How may we help you?"
					text="Email us at"
					contact="hello@superhuit.ch"
				/>
			</section>
		</Layout>
	);
};

export default StrategyPage;
